.photoStack
    margin: 1em
    position: relative
    height: 57vh
    width: 100%
    margin: 2em 0
    &>.photoStack--layerRoot
        position: absolute
        width: 100%
        height: 100%
        display: flex
        align-items: center
        justify-content: center
        &>.photoStack--layer
            $border-col: #fafafa
            $border-width: 7px
            display: flex
            flex-direction: column
            width: fit-content
            margin: 20px
            box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)
            background: $border-col
            border: $border-width solid $border-col
            outline: 1px solid #ccc
            &>img
                width: min(40vh, 50vw)
                height: auto
                user-select: none
            &>span
                min-height: 32px
                text-align: center
                padding: 10px 0 10px - calc($border-width / 2) 0
            &.photoStack--layerWide>img
                width: auto
                height: min(40vh, 50vw)
            &::after
                content: ''
                position: absolute
                top: 0
                bottom: 0
                left: 0
                right: 0
                display: block
                $overlay-col: 130  // [0,255]
                background: rgba($overlay-col, $overlay-col, $overlay-col, calc(1.8 * var(--orderPercent) * var(--orderPercent) + 0.8 * var(--orderPercent) + 0.3))
                margin: -$border-width - 1
            &:not(.photoStack--layerTop)
                // border: none
                outline: none
                box-shadow: none
                &>span
                    color: #ddd
            &.photoStack--layerTop
                &::after
                    display: none



