@use "../utils/styles"

.home-section--landing
  height: 85vh
  background: white center / cover
  &>.container
    height: 100%
    display: flex
    align-items: center
    justify-content: center
    &>h2
      color: styles.$primary
      background: #ffffffe5
      border: 4px solid styles.$primary
      outline: 4px solid white
      padding: 0.5em 1em
      border-radius: 4px

.home-section
  min-height: 90vh
  & h2
    padding-top: min(5vh, 2em)
  & .MuiButton-contained
    margin: 1em 0 2em 0
  &:not(.home-section--contrast, .home-section--noBottom)
    border-bottom: 1px solid #a2a2a2
  &.home-section--contrast
    background: #f2aa00
    & .MuiButton-contained
      background: white
      &:hover
        background: lightgrey
