@use "../utils/styles"

footer
  background: styles.$primary
  padding: 3em 0
  margin-top: 2em
  &>.container
    padding: 0.7em
    background: white
    margin-top: 1em
    text-align: center
    &>a
      color: unset!important

