@use "./utils/styles"
$ig-shadow: 0 0 0 rgba(0, 0, 0, 0)
$ig-blue: white   // image gallery color
@import "~react-image-gallery/styles/scss/image-gallery.scss"

.image-gallery-icon
  &.image-gallery-svg
    transform: scale(0.6)!important

body
  // min-height: 102vh
  // Disable buggy scrollbar disabling while in menu
  overflow: auto!important
  padding-right: 0!important

main
  padding-top: 80px

// fix bootstrap and mui conflict in reset css
a:hover
  &.MuiButton-containedPrimary
    color: black
  &.MuiButton-outlinedPrimary
    color: styles.$primary
  &.MuiTab-textColorPrimary
    color: styles.$primary

.indexPageAdminPrimaryAction
  width: 100%
  height: 80px
  margin-bottom: 2em !important

.MuiDialog-paperFullWidth
  // make full width wider
  margin: 1em!important
  width: 100%!important

.MuiDialogActions-root
  flex-wrap: wrap

.basicPage
  min-height: calc(100vh - 80px - 158px + 1px)
