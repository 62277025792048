@use "./styles"

nav
  position: fixed
  background: white
  width: 100%
  height: 80px
  z-index: 1000
  box-shadow: 2px 2px 5px 3px #0004
  padding: 10px
  &>.container-fluid
    display: flex
    gap: 2em
    & a
      color: inherit
      &>.MuiTabs-root
        flex-grow: 20

.navMobile
  display: grid
  align-items: stretch
  grid-template-columns: 48px auto 48px
  align-items: center
  gap: 1em
  @media (max-width: 396px)
    gap: 0
    grid-template-columns: 48px auto
    &>.homeStandaloneLink
      text-align: center
  &>button
    height: 48px

.navBarDrawer
  display: flex
  flex-direction: column
  min-width: 250px
  padding-top: 80px
  gap: 2em
  & a
    margin-right: 1em
    max-width: 150px
    align-self: flex-end
    text-align: end
  &>.mobileAdminAlert
    align-self: center
    & .MuiAlert-icon
      align-self: center
      & a
        color: inherit

.navDesktop
  display: grid
  grid-template-columns: 165px 1fr
  align-items: center
  padding: 0 1em
  & a
    max-width: 100px
    margin: 0 5px

.navPopper
  z-index: 1001
  background: white
  padding: 3px
  display: flex
  flex-direction: column
  text-align: center
  max-width: 120px
  gap: 5px
  border: 3px solid
  border-image-slice: 13
  $border-switcher: 23%
  border-image-source: linear-gradient(180deg, white $border-switcher, #0004 $border-switcher)
  border-radius: 4px
  & a
    color: unset
    text-decoration: none
    &:hover
      color: styles.$primary

.homeStandaloneLink
  text-decoration: none
  color: inherit!important
