@use "../utils/styles"

.adminLoginPage
    min-height: 50vh
    padding-top: 3em
    &>.container
        display: flex
        flex-direction: column
        align-items: center
        &>h2
            margin-bottom: 0.5em
        &>span
            color: styles.$error
            margin-bottom: 1.25em
        &>form
            width: 275px
            display: flex
            flex-direction: column
            align-items: start
            gap: 2em
